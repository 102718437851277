import moment from 'moment'

export default (inputList) => {
  const items = inputList.map(x => x)

  // first sort items by date and then priority
  let itemsSorted = items.sort((a, b) => {
    // sort by assigned production date (descending)
    const A = a.dateProduction
    const B = b.dateProduction
    if (A > B) return -1
    if (A < B) return 1

    return 0
  })
  
  itemsSorted = itemsSorted.map(item => {
    item.schedType = 'line'
    return item
  })
  
  ////////
  // prepare date subsummary lines
  ////////

  // account for an array of a single item
  if(itemsSorted.length <= 1) { return itemsSorted }
  
  // insert a date item at the top of the array
  itemsSorted.splice(0, 0, { schedType: 'date', date: moment(itemsSorted[0].dates.schedule).format('LL') })
  
  // working forwards from start of list, the first item (after the inserted date) will be considered the 'previous item' in the loop
  let previousItem = itemsSorted[1]

  // more than one item, start at second
  for (let i = 2; i <= itemsSorted.length - 1; ++i) {
    // find out the adjacent dates
    const previousDate = previousItem.dates.schedule
    const currentDate = itemsSorted[i].dates.schedule

    // if the date has changed from a previous item splice in a date line
    if (currentDate !== previousDate) {
      itemsSorted.splice(i, 0, { schedType: 'date', date: moment(currentDate).format('LL') })
      // set previous item to current item
      previousItem = itemsSorted[i + 1]
    } else {
      // set previous item to current item
      previousItem = itemsSorted[i]
    }


    
  }
  
  return itemsSorted
}