<template lang="pug">
div()
  v-list(subheader two-line)
    div(
      v-for='(item, i) in itemsSorted'
      :key='item._id'
    )
      v-list-tile(
        v-if="item.schedType === 'line'"
        avatar
      ).list-item
        v-list-tile-avatar(v-if="item.dashboard.image.url" tile @click="select(item)")
          img(:src="item.dashboard.image.url")
        v-list-tile-content(@click="select(item)")
          v-list-tile-title
            slot(name="line1" :item="item")
          v-list-tile-sub-title
            slot(name="line2" :item="item")
      v-list-tile.list-item.pink.lighten-5(
        v-if="item.schedType === 'date'"
      )
        v-list-tile-content
          v-list-tile-title.font-weight-black.title
            | {{ item.date }}

</template>
<script>
import moment from 'moment'
import PriorityPicker from './priority-picker'
import scheduleSort from '../../../helpers/schedule-sort-reverse'

export default {
  components: { PriorityPicker },
  props: {
    items: { type: Array },
  },
  computed: {
    itemsSorted () { return scheduleSort(this.items) }
  },
  methods: {
    select (item) { this.$emit('select', item) },
  }
}
</script>
