<template lang="pug">
v-select.pl-3(
  label="priority"
  v-model.number="priorityInput"
  :items="items"
  @change="changePriority"
  dense
  width="25%"
)
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  props: ['_idProductionInstance', 'priority'],
  data: () => ({
    items: [1, 2, 3, 4, 5],
    priorityInput: null
  }),
  methods: {
    changePriority () {
      const items = this.$store.getters['productionInstances/items']
      const item = items.find(x => x._id === this._idProductionInstance)
      item.dashboard.priority.production = this.priorityInput
      this.$store.commit('productionInstances/item', item)
      this.$store.commit('productionInstances/itemsUpdate', { list: 'items', item})
      this.$store.dispatch('productionInstances/_UPDATE')
    }
  },
  created () { this.priorityInput = this.priority}
}
</script>
