<template lang="pug">
div()
  ListSchedule(
    :items="productionInstances"
    @select="select"
  )
    template(slot="line1" slot-scope="{ item }")
      v-layout(row)
        v-flex(xs6) {{item.dashboard.designName}} - {{item.dashboard.jobSubtitle}} -
          //- span.font-weight-bold {{item.location.template.name}}
        v-flex(xs6)
          v-layout(row)
            //- v-flex(xs4)
            //-   span.font-weight-bold press:
            //-   | {{item.press.name}}
            v-flex(xs4)
              span.font-weight-bold dd date:
              template(v-if="item.dates.dropDeadOverride") {{formatDate(item.dates.dropDeadOverride)}}
              template(v-else) {{formatDate(item.dates.dropDead)}}
            v-flex.pink--text(xs4 v-if="item.minutes.override")
              | minutes: {{item.minutes.override}}
            v-flex(xs4 v-else)
              | minutes: {{item.minutes.default}}
    template(slot="line2" slot-scope="{ item }")
      v-layout(row)
        v-flex(xs5) order: {{item.dashboard.orderDisplayName}} / job#: {{item.dashboard.jobNumber}}
</template>

<script>
import moment from 'moment'
import ListSchedule from './list'
import JobProgressBar from '@/components/JobProgressBar'
import PiProgressBar from '@/components/PiProgressBar'

export default {
  components: { ListSchedule, JobProgressBar, PiProgressBar },
  data: () => ({ progressToggle: false }),
  computed: {
    productionInstances () {
      const pis = this.$store.getters['productionInstances/items'].filter(x => !x.task.isTask || !x.location.template.name)
      return pis
    },
    loaded () {
      return this.$store.state.productionInstances.loaded
    }
  },
  methods: {
    select (item) {
      // bring up a PI detail
      this.$router.push({ path: '/dashboards/production/' + item._id })
    },
    formatDate (date) {
      if (!date) { return '' }
      return moment(date).format('L')
    }

  },
  async created () {
    await this.$store.dispatch('dashboards/_LOAD_PRODUCTION_COMPLETED')
  }
}
</script>
