<template lang="pug">
v-container(fluid)
  FwdHeadingLarge production (completed)

  v-layout(row)
    v-flex
      ProductionSchedule
</template>

<script>
import ProductionSchedule from './list'

export default {
  components: { ProductionSchedule }
}
</script>
