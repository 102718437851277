<template lang="pug">
v-layout(row)
  v-flex(v-for="(item, i) in progressItems" :key="i")
    v-card.text-xs-center(
      tile
      flat
      :color="item ? 'black' : barStyles[i].color"
      :class="item ? 'font-weight-bold white--text' : ''"
    )
      v-card-text(class="pa-0") {{barStyles[i].abbr}}
</template>
<script>
export default {
  props: {
    progress: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    barStyles: [
    { abbr: 'rip', color: 'lime lighten-2' },
    { abbr: 'scrn', color: 'deep-orange lighten-4' },
    { abbr: 'inks', color: 'deep-orange lighten-3' },
    { abbr: 'approved', color: 'green lighten-3' },
    ]
  }),
  computed: {
    progressItems() {
      return Object.keys(this.progress).map(key => {
        return this.progress[key]
      })
    }
  }
}
</script>